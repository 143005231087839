import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      allSanityDistributor {
        nodes {
          address
          contact_email
          contact_name
          country
          distributor_name
        }
      }
    }
  `)

  return data.allSanityDistributor.nodes
}
