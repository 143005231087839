import React from "react"
import Layout from "../components/layoutNew"
import TwoColumnLayout from "../components/layoutNew/innerLayouts/twoColumns"
import distributorsQuery from "../components/static/queries/allDistributors"
import DistributorList from "../components/static/pageComponents/distributors/distributorList"

export default () => {
  const distributors = distributorsQuery()

  return (
    <Layout>
      <TwoColumnLayout
        title="Distributors"
        body="Get in touch with the stockists for your region to discuss becoming
            a stockist. Find the stockist for your region below."
      >
        <DistributorList distributors={distributors} />
      </TwoColumnLayout>
    </Layout>
  )
}
