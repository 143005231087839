import React from "react"
import style from "./distributorList.module.scss"
import InfoCard from "../../../../utilities/infoCards"
import CardList from "../../../../utilities/cardList"
import GridLink from "../../../../utilities/gridLink"

export default ({ distributors }) => (
  <div className={style.container}>
    <CardList
      linkText="Can’t find what you’re looking for? Get in touch with our distributor Global Air Supplies."
      linkUrl="/contact"
    >
      {distributors.map(
        ({ contact_name, contact_email, country, distributor_name }) => (
          <InfoCard>
            <p className={style.country}>{country}</p>
            <h2 className={style.distributorName}>{distributor_name}</h2>
            <p className={style.contact}>Contact name: {contact_name}</p>
            <a className={style.email} href={`mailto:${contact_email}`}>
              {contact_email}
            </a>
          </InfoCard>
        )
      )}
      <GridLink
        caption="Looking to buy for yourself?"
        linkText="Find a stockist"
        linkUrl="/stockists"
      />
    </CardList>
  </div>
)
